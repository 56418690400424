import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.scss']
})
export class QAComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  openQuestion(question: string){
    console.log(question);
    this.modalService.open(question, {size: 'lg'});
  }
  openCall(){
    this.modalService.open("Rückruf anfragen");
  }
}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { faColumns, faDoorOpen, faMap,faGlobe, faCheckSquare,faUsers,faQuestion, faRuler, faCalendar, faGlobeEurope, faRedo, faUserAlt, faChartPie, faPaperclip} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-howto',
  templateUrl: './howto.component.html',
  styleUrls: ['./howto.component.scss']
})
export class HowtoComponent implements OnInit {
  
  safeURL;
  youtubeLoaded: boolean = false;
  videoURL: string="https://youtu.be/hBsaZ_2LpT0";
  constructor( private _sanitizer: DomSanitizer){
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoURL);
 }

  ngOnInit() {
  }


}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './components/landing-page-component/landing-page.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ContactComponent } from './components/contact/contact.component';
import { AGBComponent } from './components/agb/agb.component';
import { DatenschutzComponent } from './components/datenschutz/datenschutz.component';
import { DescriptionServiceComponent } from './components/description-service/description-service.component';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { PriceComponent } from './components/price/price.component';
import { HowtoComponent } from './components/howto/howto.component';
import { AddonsComponent } from './components/addons/addons.component';


const routes: Routes = [
  { path: 'kostenlos-testen', component: SignUpComponent },
  { path: 'landingpage',      component: LandingPageComponent },
  { path: 'agb',      component: AGBComponent },
  { path: 'datenschutz',      component: DatenschutzComponent },
  { path: 'contact',      component: ContactComponent },
  { path: 'descriptionservice',      component: DescriptionServiceComponent },
  { path: 'impressum',      component: ImpressumComponent },
  {path: 'preis',     component: PriceComponent},
  {path: 'addons',     component: AddonsComponent},
  {path: 'howto',     component: HowtoComponent},
  { path: '',
    redirectTo: '/landingpage',
    pathMatch: 'full'
  },
  { path: '**', component: LandingPageComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    onSameUrlNavigation: 'ignore',
    useHash: true,
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

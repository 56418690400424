import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './components/landing-page-component/landing-page.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { AGBComponent } from './components/agb/agb.component';
import { DatenschutzComponent } from './components/datenschutz/datenschutz.component';
import { DescriptionServiceComponent } from './components/description-service/description-service.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { ImpressumComponent } from './components/impressum/impressum.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SignUpService } from './components/sign-up/sign-up.service';
import {CookieService} from 'ngx-cookie-service';
import { PriceComponent } from './components/price/price.component';
import { TestnowComponent } from './components/testnow/testnow.component';
import { FeaturesComponent } from './components/features/features.component';
import { QAComponent } from './components/qa/qa.component';
import {CoronaFeatures} from "./components/corona/corona.component";
import { HowtoComponent } from './components/howto/howto.component';
import { VideoplayerComponent } from './components/howto/videoplayer/videoplayer.component';
import { AddonsComponent } from './components/addons/addons.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    SignUpComponent,
    ContactComponent,
    FooterComponent,
    AGBComponent,
    DatenschutzComponent,
    DescriptionServiceComponent,
    QuestionsComponent,
    ImpressumComponent,
    PriceComponent,
    TestnowComponent,
    FeaturesComponent,
    CoronaFeatures,
    QAComponent,
    HowtoComponent,
    VideoplayerComponent,
    AddonsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  providers: [SignUpService, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }

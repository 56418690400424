import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignUpService {
  baseurl = "https://api2.reservino.de";
  constructor(private http: HttpClient) {
  }

  authenticate(): Observable<HttpResponse<any>> {
    let json= {'email':'e56dba6412d9bb311cc047fbb9c40f68','password':'a3af035234e57d5a1a515550b80fc7a158763f83'};
    return this.http.post(
      this.baseurl + "/authenticate",
      JSON.stringify(json),
      {
        headers: {
          'Content-type': 'application/json'
        },
        observe: 'response'
      }
    );
  }

  createNewTendant(data: any, token: string): Observable<HttpResponse<Object>> {

    data.cardToken = '123';
    data.subscriptionPlan = '123';

    console.log("create new tendant " + data);
    return this.http.post(
      this.baseurl + "/tenants",
      data,
      {
        headers: {
          'Authorization': "Bearer "+token,
        },
        observe: 'response',
      }
    )
  }

  setCredentials(mail, pass, passLen, token: string) {

    console.log("call logout")
    this.http.post(
      this.baseurl + "/authenticate/logout",
      null,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json; charset=UTF-8',
          'Authorization': "Bearer "+token,
        },
        withCredentials: true,
        observe: 'response'
      }).subscribe(resp => {
        console.log("logout successfull");
        localStorage.clear();
        sessionStorage.clear();

          localStorage.setItem("reservinoUser", mail);
          localStorage.setItem("reservinoPassword", pass);
          localStorage.setItem("reservinoPasswordLength", passLen);
          localStorage.setItem("reservinoAutologin", 'true');


      });
  }
}

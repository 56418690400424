import { Component, OnInit } from '@angular/core';

import { faCheck, faTh, faGlobeEurope, faMobileAlt, faPencilAlt, faBoxOpen, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';

import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  faCheck = faCheck;
  faTh = faTh;
  faGlobeEurope = faGlobeEurope;
  faMobileAlt = faMobileAlt;
  faPencilAlt=faPencilAlt;
  faYoutube=faYoutube;
  faBoxOpen = faBoxOpen;
  faAngleDoubleUp = faAngleDoubleUp;
  currentJustify = "fill";
  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigateToPreis(){
    this.router.navigate(['preis']);
  }

  redirectToKostenlosTesten(){
    this.router.navigate(['contact']);
  }

}

import { Component, OnInit } from '@angular/core';
import { SignUpService } from './sign-up.service';
import { MustMatch } from '../../helper/must-match.validator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { keccak_512 } from 'js-sha3';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {

  registerForm: FormGroup;
  submitted = false;
  tenantCreated: boolean;
  loading: boolean;
  authToken: string;
  creationResponse: object;
  hasErrorInCreation = false;

  constructor(private service : SignUpService, private formBuilder: FormBuilder) {
    this.tenantCreated = false;
    this.loading = false;
    this.creationResponse = null;

    this.registerForm = this.formBuilder.group({
      restaurantName: ['', Validators.required],
      forename: ['', Validators.required],
      lastname: ['', Validators.required],
      street: ['', Validators.required],
      city: ['', Validators.required],
      plz: ['', [Validators.required, Validators.pattern("[0-9]*")]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      marketingSource: [''],
      acceptTerms: [false, Validators.requiredTrue]
    }, {
      validator: MustMatch('password', 'confirmPassword')
  });


  }

  get f() { return this.registerForm.controls; }


  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      this.loading = false;
      console.log("invalid: " + this.loading + " ," + this.hasErrorInCreation);
      return;
    }

    this.createNewTendant(this.registerForm.value);
  }

  onReset() {
    this.submitted = false;
    this.loading = false;
    this.creationResponse = null;
    this.registerForm.reset();
  }

  async createNewTendant(tendant: FormGroup) {
    this.loading = true;
    console.log("create new tendant" + this.loading + " ," + this.hasErrorInCreation)

    let str = JSON.stringify(tendant)
    let obj = JSON.parse(str);

    this.service.authenticate().subscribe(resp => {
      let token = resp.body.token;


      obj.password = keccak_512(obj.password);
      this.creationResponse = this.service.createNewTendant(obj, token).subscribe(resp => {
        console.log("tendant created");
        this.creationResponse = resp.body;
        this.service.setCredentials(obj.email, obj.password, obj.password.length, token);
        this.tenantCreated = true;
      },
        error => {
          console.log("error in tendant creation " + error);
          this.creationResponse = error.error;
          this.hasErrorInCreation = true;
          this.loading = false
        });
    });
  }



}

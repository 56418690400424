import {Component, OnInit} from '@angular/core';
import {
  faClock,
  faDoorOpen,
  faEnvelope,
  faExclamationCircle,
  faExternalLinkAlt,
  faGavel,
  faGlobeEurope,
  faIdCard,
  faLightbulb
} from '@fortawesome/free-solid-svg-icons';
import {Router} from "@angular/router";

@Component({
  selector: 'corona',
  templateUrl: './corona.component.html',
  styleUrls: ['./corona.component.scss']
})
export class CoronaFeatures implements OnInit {
  faIdCard = faIdCard;
  faDoorOpen = faDoorOpen;
  faExternalLinkAlt = faExternalLinkAlt;
  faClock = faClock;
  faExclamationCircle = faExclamationCircle;
  faLightbulb = faLightbulb;
  faEnvelope = faEnvelope;
  faGavel = faGavel;
  faGlobeEurope = faGlobeEurope;

  constructor(private router: Router) { }


  ngOnInit() {
  }

  navigateToKostenlosTesten() {
    this.router.navigate(['kostenlos-testen']);
  }
}

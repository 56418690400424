import { Component, OnInit } from '@angular/core';
import { faColumns, faDoorOpen, faMap,faGlobe, faCheckSquare,faUsers,faQuestion, faRuler, faCalendar, faGlobeEurope, faRedo, faUserAlt, faChartPie, faPaperclip, faAddressCard} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  faColumns =faColumns;
  faDoorOpen = faDoorOpen;
  faMap = faMap;
  faRuler = faRuler;
  faCalendar = faCalendar;
  faGlobeEurope = faGlobeEurope;
  faRedo = faRedo;
  faUserAlt = faUserAlt;
  faChartPie = faChartPie;
  faPaperclip=faPaperclip;
  faGlobe = faGlobe;
  faCheckSquare=faCheckSquare;
  faUsers=  faUsers;
  faQuestion=faQuestion;
  faAdressCard = faAddressCard;
  constructor() { }

  ngOnInit() {
  }

}

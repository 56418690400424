import { Component, OnInit } from '@angular/core';
import {faFilePdf, faLink} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.scss']
})
export class AGBComponent implements OnInit {
  faFilePdf = faFilePdf;
  faLink = faLink;

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';

import { faColumns, faDoorOpen, faMap,faGlobe, faCheckSquare,faUsers,faQuestion, faRuler, faCalendar, faGlobeEurope, faRedo, faUserAlt, faChartPie, faPaperclip} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.scss']
})
export class AddonsComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }

}

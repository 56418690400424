import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-testnow',
  templateUrl: './testnow.component.html',
  styleUrls: ['./testnow.component.scss']
})
export class TestnowComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  redirectToKostenlosTesten(){
    this.router.navigate(['kostenlos-testen']);
  }
}

import { Component, Input, OnInit } from '@angular/core'; 
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  
  selector: 'app-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss']
})
export class VideoplayerComponent {
  safeSrc:SafeResourceUrl;
  safeSrc2:SafeResourceUrl;
  safeSrc3:SafeResourceUrl;
  safeSrc4:SafeResourceUrl;
  safeSrc5:SafeResourceUrl;
  safeSrc6:SafeResourceUrl;
  
  safeSrc7:SafeResourceUrl;
  safeSrc8:SafeResourceUrl;
  safeSrc9:SafeResourceUrl;
  constructor(private saninizer: DomSanitizer) {

    this.safeSrc = this.saninizer.bypassSecurityTrustResourceUrl("https://youtube.com/embed/hBsaZ_2LpT0");
    this.safeSrc2 = this.saninizer.bypassSecurityTrustResourceUrl("https://youtube.com/embed/mnfXAk6rMEE");
    this.safeSrc3 = this.saninizer.bypassSecurityTrustResourceUrl("https://youtube.com/embed/BpnxMMo9lBg");
    
    this.safeSrc4 = this.saninizer.bypassSecurityTrustResourceUrl("https://youtube.com/embed/eknMbXPDp94");
    this.safeSrc5 = this.saninizer.bypassSecurityTrustResourceUrl("https://youtube.com/embed/oxKoeDqql40");
    this.safeSrc6 = this.saninizer.bypassSecurityTrustResourceUrl("https://youtube.com/embed/lmujwsq_GzI");

    this.safeSrc7 = this.saninizer.bypassSecurityTrustResourceUrl("https://youtube.com/embed/HYU0QCHNa5c");
    this.safeSrc8 = this.saninizer.bypassSecurityTrustResourceUrl("https://youtube.com/embed/Y6_5lhOpFZY");
    this.safeSrc9 = this.saninizer.bypassSecurityTrustResourceUrl("https://youtube.com/embed/oZZLT0qE3pE");
    
  }
}